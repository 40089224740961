<template>
  <b-row align-h="center">
    <b-col>
      <div class="text-right">
        <b-button
          v-b-modal.add-modal
          variant="primary"
          class="mb-1"
        >
          <span class="text-nowrap">Add Language</span>
        </b-button>
      </div>

      <b-card header="Search">
        <b-row>
          <b-col>
            <search
              placeholder="Name"
              :search-query.sync="searchQuery"
              @refresh="refreshTable"
            />
          </b-col>
          <b-col
            md="2"
            class="mt-1 mt-md-0"
          >
            <search-button @refresh="refreshTable" />
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <languages-table
          ref="parentTable"
          v-bind="{
            getLanguages,
            pagination
          }"
          :language.sync="language"
        />
      </b-card>

      <pagination
        :total-rows="pagination.totalRows"
        :per-page="pagination.perPage"
        :current-page.sync="pagination.currentPage"
      />
    </b-col>

    <div class="d-flex align-items-center justify-content-end m-2">
      <!-- modal Add Language -->
      <b-modal
        id="add-modal"
        centered
        title="Add Language"
        hide-footer
      >
        <add-language
          v-bind="{textDirections}"
          @refresh="refreshTable"
        />
      </b-modal>
      <!-- Edit -->
      <b-modal
        id="edit-modal"
        centered
        title="Edit Language"
        hide-footer
      >
        <edit-language
          v-bind="{
            textDirections,
            language
          }"
          @refresh="refreshTable"
        />
      </b-modal>
    </div>
  </b-row>
</template>

<script>
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import Search from '@/common/components/common/Table/Search.vue'
import AddLanguage from '@/common/components/GeneralSettings/languages/AddLanguage.vue'
import EditLanguage from '@/common/components/GeneralSettings/languages/EditLanguage.vue'
import LanguagesTable from '@/common/components/GeneralSettings/languages/LanguagesTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'

export default {
  components: {
    AddLanguage,
    EditLanguage,
    LanguagesTable,
    Pagination,
    Search,
    SearchButton,
  },
  data() {
    return {
      textDirections: [
        {
          value: 'rtl',
          text: 'RTL',
        },
        {
          value: 'ltr',
          text: 'LTR',
        },
      ],
      pagination: {
        totalRows: 5,
        currentPage: 1,
        perPage: 10,
      },
      language: {},
      searchQuery: '',

    }
  },
  methods: {
    getLanguages() {
      const promise = this.$entities.get(`internalops/languages?page=${this.pagination.currentPage}`, {
        params: { name: this.searchQuery },
      })
      return promise.then(res => {
        const items = res.data.data.data
        this.pagination.perPage = res.data.data.per_page
        this.pagination.totalRows = res.data.data.total
        return items || []
      }).catch(() => [])
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>
